<template>
  <div class="home-page">
    <div class="header-wrapper">
      <div class="home-header">
        <div class="header-logo">
          <div class="header-logo">
            <img src="../assets/logo.jpg" alt="logo" />
          </div>
        </div>
        <div class="nav-buttons">
          <p><a href="#">主页</a></p>
          <p><a href="#company-img">公司介绍</a></p>
          <p><a href="#product-img">产品介绍</a></p>
          <p><a href="#example-img">合作伙伴</a></p>
          <p><a href="#contact-block">联系我们</a></p>
        </div>
        <div class="login-button" @click="handleLogin">
          立即登录
        </div>
      </div>
    </div>
    <div class="main-content">
      <el-carousel :height="imgHeight">
        <el-carousel-item>
          <img ref="imgH" style="width: 100%;" src="../assets/C1.jpg" />
        </el-carousel-item>
        <el-carousel-item>
          <img style="width: 100%;" src="../assets/C2.jpg" />
        </el-carousel-item>
      </el-carousel>
      <div class="main-imgs">
        <img id="company-img" src="../assets/company.jpg" />
        <img id="product-img" src="../assets/product.jpg" />
        <img id="more-img" src="../assets/more.jpg" />
      </div>
      <div class="intellectual-property">
        <el-carousel :height="bottomImageHeight">
          <el-carousel-item>
            <img ref="imgB" width="90%" src="../assets/auth1.jpg" />
          </el-carousel-item>
          <el-carousel-item>
            <img width="90%" src="../assets/auth2.jpg" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="main-imgs">
        <img id="example-img" src="../assets/examples.png" />
      </div>
      <div class="footer" id="contact-block">
        <el-row>
          <el-col :span="12">
            <p>公司地址：福建省厦门市集美区软件园三期C07栋901室</p>
            <p>联系电话：17306009091 马经理</p>
            <div>
              <div class="code-image">
                <img src="../assets/official.jpg" />
                <p>公众号</p>
              </div>
              <div class="code-image">
                <img src="../assets/contact.jpg" />
                <p>微信</p>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div id="map-container"></div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
  <!-- <el-backtop :bottom="backtopBottom">
    <div style="
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 3px;
        box-shadow: var(--el-box-shadow-dark);
        text-align: center;
        line-height: 70px;
        color: white;
      ">
      <el-icon color="blue" :size="26">
        <ArrowUpBold />
      </el-icon>
    </div>
  </el-backtop> -->
  <div class="comment-button" @click="handleComment">
    <el-icon color="blue" :size="26">
      <ChatDotRound />
    </el-icon>
    <p>
      留言
    </p>
  </div>
  <el-dialog v-model="commentBoxVisible" title="留言" center>
    <el-form :model="form">
      <el-form-item label="公司名称" :label-width="formLabelWidth">
        <el-input v-model="form.companyName" autocomplete="off" />
      </el-form-item>
      <el-form-item label="电话" :label-width="formLabelWidth">
        <el-input v-model="form.phoneNumber" autocomplete="off" />
      </el-form-item>
      <el-form-item label="联系人" :label-width="formLabelWidth">
        <el-input v-model="form.contact" autocomplete="off" />
      </el-form-item>
      <el-form-item label="意向产品" :label-width="formLabelWidth">
        <el-input v-model="form.product" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" size="large" @click="handleSumit">
          &nbsp;&nbsp;提交&nbsp;&nbsp;
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import axios from 'axios';

window._AMapSecurityConfig = {
  securityJsCode: '' // '「申请的安全密钥」',
}
export default {
  name: 'HomePage',
  data() {
    return {
      zoom: 18,
      imgHeight: '0',
      bottomImageHeight: '0',
      position: [118.036538, 24.622087],
      markUrl: require('../assets/mark_bs.png'),
      commentBoxVisible: false,
      form: {
        companyName: '',
        phoneNumber: '',
        contact: '',
        product: ''
      },
      formLabelWidth: '100px',
    }
  },
  mounted() {
    this.imgLoad();
    this.initAMap();
    window.addEventListener("resize", this.imgLoad, false);
  },
  unmounted() {
    window.removeEventListener("resize", this.imgLoad, false);
  },
  methods: {
    initAMap() {
      AMapLoader.load({
        key: "9b3ab9bcfd45afca0de053276c8e14e8",
        plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.ControlBar", 'AMap.Geocoder', 'AMap.Marker',
          'AMap.CitySearch', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.InfoWindow'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        // 获取到作为地图容器的DOM元素，创建地图实例
        this.map = new AMap.Map("map-container", { //设置地图容器id
          zoom: this.zoom, // 地图显示的缩放级别
          dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
          center: this.position, // 初始化中心点坐标
        })

        const content =
          `<div class="amap-icon" style="position: absolute; overflow: hidden; width: 19px; height: 33px; opacity: 1;"><img src="${this.markUrl}" style="width: 19px; height: 33px; top: 0px; left: 0px;"></div>`;
        const marker = new AMap.Marker({
          position: this.position,
          content,
        })
        this.map.add(marker);
      }).catch(e => {
        console.log(e)
      })
    },
    imgLoad() {
      setTimeout(() => {
        this.imgHeight = this.$refs.imgH ? `${this.$refs.imgH.height}px` : '0';
        this.bottomImageHeight = this.$refs.imgB ? `${this.$refs.imgB.height}px` : '0';
      }, 1000);
    },
    handleLogin() {
      window.location.href = "https://02.laitayun.com";
    },
    handleComment() {
      this.commentBoxVisible = true;
    },
    handleSumit() {
      // 请求接口
      axios.post('https://laitayun.com/api/cloud/leaveMessage',this.form).then(() => {
        
        this.commentBoxVisible = false;
      })
      this.commentBoxVisible = false;
    }
  }

}
</script>

<style>
.header-wrapper {
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border-bottom: 0.5px solid #eee;
}

.home-header {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
}

.header-logo {
  height: 100%;
  padding-left: 20px;
}

.header-logo img {
  height: 100%;
}

.nav-buttons {
  height: 100%;
}

.nav-buttons p {
  padding: 0 20px;
  display: inline-block;
  margin: 0;
  height: 100%;
  line-height: 70px;
  cursor: pointer;
}

.nav-buttons a {
  text-decoration: none;
  color: black;
}

.login-button {
  height: 100%;
  line-height: 70px;
  width: 100px;
  background-color: #2f72b7;
  color: white;
  cursor: pointer;
}

.el-backtop {
  width: 80px;
  height: 60px;
  background-color: transparent !important;
}

.main-content {
  margin-top: 70px;
}

.home-page {
  margin: 0;
}

.main-imgs {
  margin-top: 4px;
}

.main-imgs img {
  width: 100%;
}

#company-img {
  margin-top: -70px;
  padding-top: 70px;
}

#product-img {
  margin-top: -70px;
  padding-top: 70px;
}

.intellectual-property {
  padding: 16px 100px;
}

.intellectual-property img {
  width: 100%;
}

.footer {
  text-align: left;
  padding: 40px;
  background-color: #686868;
  color: white;
}

.footer img {
  width: 150px;
  height: 150px;
}

.code-image {
  padding: 5px 20px;
  display: inline-block;
}

.code-image p {
  text-align: center;
  margin: 6px;
}

#map-container {
  width: 100%;
  height: 100%;
}

.comment-button {
  padding: 10px;
  width: 70px;
  height: 50px;
  position: fixed;
  bottom: 40vh;
  right: 40px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  box-shadow: var(--el-box-shadow-dark);
  text-align: center;
  color: blue;
  cursor: pointer;
}

.comment-button p {
  margin: 0;
  font-size: 14px;
}
</style>
